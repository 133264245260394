import React, { useState } from "react";
import { useRegister } from "../viewModels/RegisterViewModel";
import "./stylesheets/Register.css";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { register, loading, error } = useRegister();

  const passwordsMatch = password === confirmPassword;
  const allFieldsFilled =
    email !== "" && password !== "" && confirmPassword !== "";

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (passwordsMatch) {
      await register(email, password);
    }
  };

  return (
    <div
      className="register"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ padding: "10px", fontSize: "16px", width: "200px" }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ padding: "10px", fontSize: "16px", width: "200px" }}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          style={{ padding: "10px", fontSize: "16px", width: "200px" }}
        />
        <button
          type="submit"
          disabled={!allFieldsFilled || !passwordsMatch || loading}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            cursor:
              !allFieldsFilled || !passwordsMatch || loading
                ? "not-allowed"
                : "pointer",
            backgroundColor:
              !allFieldsFilled || !passwordsMatch || loading
                ? "#aaa"
                : "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            transition: "background-color 0.3s",
          }}
        >
          Register
        </button>
      </form>
      {error && (
        <p style={{ color: "red", textAlign: "center" }}>{error.message}</p>
      )}
    </div>
  );
}

export default Register;
