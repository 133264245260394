import React from "react";
import "./stylesheets/NotFound.css";

function NotFound() {
  return (
    <div id="contents">
      <div id="message">
        <h1 className="not-found-header">
          <div className="word-row">
            <span className="letter letter-blur letter-flash">P</span>
            <span className="letter letter-blur">a</span>
            <span className="letter letter-blur">g</span>
            <span className="letter letter-blur">e</span>
          </div>
          <div className="word-row">
            <span className="letter letter-blur">n</span>
            <span className="letter letter-blur letter-flash">o</span>
            <span className="letter letter-blur">t</span>
          </div>
          <div className="word-row">
            <span className="letter letter-blur letter-flash">f</span>
            <span className="letter letter-blur">o</span>
            <span className="letter letter-blur">u</span>
            <span className="letter letter-blur letter-flash">n</span>
            <span className="letter letter-blur">d</span>
          </div>
        </h1>
        <p>ページが見つかりませんでした。</p>
      </div>
    </div>
  );
}

export default NotFound;
