import { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCuDzqanjW9gDM-I_B1d3JV2-wdaNbUA-I",
    authDomain: "jim112o.firebaseapp.com",
    projectId: "jim112o",
    storageBucket: "jim112o.appspot.com",
    messagingSenderId: "595329780009",
    appId: "1:595329780009:web:287dd35b3291502d308f38",
    measurementId: "G-CPYRY3ZJN8"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

export function useRegister() {
    
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const register = async (email: string, password: string) => {
    setLoading(true);
    try {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        setLoading(false);
      return result;
    } catch (err) {
      setLoading(false);
      setError(err as Error);
    }
  }

  return {
    register,
    loading,
    error,
  };
}
